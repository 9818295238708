import request from '@/utils/request'


// 查询报名列表
export function listOrder(query) {
  return request({
    url: '/driving-school/driving-school-order/list',
    method: 'get',
    params: query
  })
}

// 查询报名分页
export function pageOrder(query) {
  return request({
    url: '/driving-school/driving-school-order/page',
    method: 'get',
    params: query
  })
}


// 查询报名详细
export function getOrder(data) {
  return request({
    url: '/driving-school/driving-school-order/detail',
    method: 'get',
    params: data
  })
}

// 根据报名查询驾照类型
export function getTrainingCourse(data) {
  return request({
    url: '/driving-school/driving-school-order/getTrainingCourse',
    method: 'get',
    params: data
  })
}

// 根据报名查询驾照类型
export function getCoach(data) {
  return request({
    url: '/driving-school/driving-school-order/getCoach',
    method: 'get',
    params: data
  })
}

export function getTraining() {
  return request({
    url: '/driving-school/driving-school-training/list',
    method: 'get'
  })
}

export function getCampus() {
  return request({
    url: '/driving-school/driving-school-campus/list',
    method: 'get'
  })
}
// 新增报名
export function addOrder(data) {
  return request({
    url: '/driving-school/driving-school-order/add',
    method: 'post',
    data: data
  })
}


// 赠送福利金额
export function giveWelfare(data) {
  return request({
    url: '/driving-school/driving-school-order/giveWelfare',
    method: 'post',
    data: data
  })
}


// 修改报名
export function updateOrder(data) {
  return request({
    url: '/driving-school/driving-school-order/edit',
    method: 'post',
    data: data
  })
}

// 删除报名
export function delOrder(data) {
  return request({
    url: '/driving-school/driving-school-order/delete',
    method: 'post',
    data: data
  })
}
