<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
<!--      <a-form-model-item label="图标" prop="ico" >-->
<!--        <a-input v-model="form.ico" placeholder="请输入图标" />-->
<!--      </a-form-model-item>-->



      <a-form-model-item label="福利金额" prop="welfareSum" >
        <a-input v-model="welfareSum" @change="limitPrice()"
                 :placeholder="$t('请输入')+$t('福利金额')"/>
      </a-form-model-item>

      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            赠送
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {  giveWelfare } from '@/api/driving-school/order'

export default {
  name: 'GiveForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '赠送福利金额',
      uploadLoading: false,
      id:null,
      welfareSum: "",
      // 表单参数
      form: {
        id: null,
        title: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    limitPrice() {
      let val = this.welfareSum.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, '$1');
      if (val == null || val == undefined || val == '' || val<0) {
        val = 0
      }
      if (val > 21474836) {
        val = 21474836
      }
      this.welfareSum = val
    },

    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        title: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },

    /** 修改按钮操作 */
    handleUpdate (row) {
      this.open = true
      this.reset()
      this.id=row.id
      console.log(111111111111111,row)

    },
    /** 提交按钮 */
    submitForm: function () {
      this.submitLoading = true
      if (this.id !== undefined && this.id !== null) {
        const from = {
          "id": this.id,
          "welfareSum": this.welfareSum
        } ;
        console.log(222222222222,from)
        giveWelfare(from).then(response => {
          this.$message.success(
            '赠送成功成功',
            3
          )
          this.open = false
          this.$emit('ok')
        }).finally(() => {
          this.submitLoading = false
        })
      }
    }
  }
}
</script>
